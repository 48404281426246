<template>
  <v-row>
    <v-col cols="12" md="6">
      <select-warehouse-model
        v-model="id_warehouse"
        :label="$t('labels.warehouse')"
        class="mb-5"
        dense
        outlined
        clearable
        hide-details
        @onFilter="onWarehouseChange"
      ></select-warehouse-model>

      <input-qr-scan-model
        ref="inputQr_1"
        v-model="cell_id"
        class="mb-5"
        :label="$t('labels.scan_position_to_confirm')"
        @keyupEnter="showPopupConfirm"
      ></input-qr-scan-model>

      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>

      <v-dialog v-model="dialogConfirm" persistent max-width="350px">
        <v-card>
          <v-card-title class="text-h6">
            {{ $t("labels.confirm_position") }}
          </v-card-title>
          <v-card-text>
            <div
              v-html="
                $t('messages.confirm_roll_position', { position: cell_id })
              "
            ></div>
          </v-card-text>

          <v-card-text>
            <input-qr-scan-model
              ref="inputQr_2"
              v-model="confirm_cell_id"
              class="mb-5"
              :label="$t('labels.scan_position_to_confirm')"
              @keyupEnter="rollCell"
            ></input-qr-scan-model>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogConfirm = false">
              {{ $t("labels.cancel") }}
            </v-btn>
            <v-btn color="green darken-1" text @click="rollCell">
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LocationRoll",
  components: {},
  data: () => ({
    id_warehouse: null,
    cell_id: null,
    confirm_cell_id: null,
    isLoading: false,
    errMsg: null,
    validates: [],
    dialogConfirm: false,
  }),
  methods: {
    onWarehouseChange() {
      this.cell_id = null;

      setTimeout(() => {
        this.$refs.inputQr_1.focusInput();
      }, 100);
    },

    async showPopupConfirm() {
      this.dialogConfirm = true;
      setTimeout(() => {
        this.$refs.inputQr_2.focusInput();
      }, 100);
    },

    async hidePopupConfirm() {
      this.dialogConfirm = false;
      this.confirm_cell_id = null;

      setTimeout(() => {
        this.$refs.inputQr_1.focusInput();
      }, 100);
    },

    async rollCell() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (this.cell_id !== this.confirm_cell_id) {
        this.$vToastify.warning(this.$t("messages.wrong_position"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;
      this.errMsg = null;
      this.validates = [];
      try {
        await httpClient.post("/warehouse-location/v1/roll-cell", {
          id_warehouse: this.id_warehouse,
          cell_id: this.cell_id,
        });
        this.isLoading = false;
        this.cell_id = null;
        document.getElementById("success_sound_player").play();
        await this.hidePopupConfirm();
      } catch (e) {
        this.isLoading = false;
        this.errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.validates) ||
          [];
        if (validates && validates.length > 0) {
          validates.forEach((v) => {
            this.validates.push(v.key);
          });
        }
        document.getElementById("error_sound_player").play();
        await this.hidePopupConfirm();
      }
    },
  },
};
</script>

<style scoped></style>
